body{
   background-image: url("../public/brand/images/bg.jpg");
    li{margin-bottom: 15px}
}
.header-logo {
    height: 70%;
    width: 6vw;
}
.bonus_wrapper{
    justify-content: end;
    .bonus_container{
        margin-right: 70px;
        img{
            height: 130% !important;
        }
    }

}
.bonus_slider {
    position: relative;
    width: 100%;
    //background: #000000;
    margin-top: 70px;
box-shadow: none;
}
.btn.bonus {
    margin-top: 15px;
}
.search_icon{
    span{
        color: #f6f6f6;
    }
}
.jackpot_wrapper{
    background-color: rgba(43, 3, 113, 0.65);
}
.header-component{
    background: unset;
}

@media screen and (max-width: 600px) {
    .carousel-initialized{
        padding: 0 !important;
    }
    .carousel-item{
        width: 100% !important;
    }
    .bonus_wrapper{
        justify-content: center;
        background-size: cover!important;
        background-position-x: -450px !important;


        .bonus_container{
            margin-right: 0px;
        }
    }
    .btn.bonus {
        margin-top:10px;
        height: initial;
    }
}